// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//import 'mdbootstrap-pro/js/mdb.min';
//import 'mdbootstrap-pro/js/addons/datatables.min';
//import 'mdb-ui-kit/js/mdb.min';

// The basics
require("@rails/ujs").start()
//require("turbolinks").start()
require("@rails/activestorage").start()
require('jquery')
require('popper.js')
require("channels")

// The stuff we want to use
//require("mdbootstrap-pro")
//require("mdb-ui-kit")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)